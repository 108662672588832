<template>
  <div class="wrapper">
    <CCard class="mb-0">
      <CCardHeader>
        販売店支店・営業所管理
        <CBadge color="success" class="ml-2">{{ agencyName }}({{ $route.params.id }})</CBadge>

        <div class="card-header-actions">
          <CBadge color="success" class="mr-5 pointer" @click="$router.back()">戻る</CBadge>
          <CLink class="card-header-action btn-minimize" @click="isCollapsed = !isCollapsed">
            <CIcon :name="`cil-chevron-${isCollapsed ? 'top':'bottom'}`"/>
          </CLink>
        </div>
      </CCardHeader>
      <CCollapse :show="!isCollapsed" :duration="200">
        <CCardBody>
          <CRow>
            <CCol col="6">
              <CInput class="mt-0" label="事業所名称" horizontal v-model="formData.agencyBranchName"/>
            </CCol>
          </CRow>

          <CRow class="mt-0">
            <div class="col-6">
              <div role="group" class="mt-0 form-group form-row">
                <label for="prefectureId" class="col-form-label col-sm-3">都道府県</label>
                <div class="col-sm-9">
                  <v-select
                      id="prefectureId"
                      v-model="formData.prefectureId"
                      :options="prefectures"
                      key="prefectureId"
                      label="prefectureName"
                      :reduce="prefectures => prefectures.prefectureId">
                    <template slot="no-options">データがありません。</template>
                  </v-select>
                </div>
              </div>
            </div>

<!--            <div class="col-6">-->
<!--              <div role="group" class="mt-0 form-group form-row">-->
<!--                <label for="cityId" class="col-form-label col-sm-3">市区町村</label>-->
<!--                <div class="col-sm-9">-->
<!--                  <v-select id="cityId"-->
<!--                            v-model="formData.cityId"-->
<!--                            :options="cities"-->
<!--                            key="id"-->
<!--                            label="name"-->
<!--                            :reduce="cities => cities.id"-->
<!--                            class="mt-0">-->
<!--                    <template slot="no-options">データがありません。</template>-->
<!--                  </v-select>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
            <CCol col="6">
              <CInput class="mt-0" horizontal label="市区町村" v-model="formData.city"/>
            </CCol>
          </CRow>
          <CRow class="mt-0">
            <CCol col="6">
              <CInput class="mt-0" horizontal label="連絡先担当者名" v-model="formData.contactName"/>
            </CCol>
            <CCol col="6">
              <CInput class="mt-0" horizontal label="連絡先電話番号" @keypress="isNumber($event)" v-model="formData.contactTel"/>
            </CCol>
          </CRow>

          <CRow class="mt-0">
            <CCol col="6">
              <CInput class="mt-0" horizontal label="担当者についてメモ" v-model="formData.contactMemo"/>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <SaveButtons :id="formData.id" @formInit="formInit" @save="save"/>
        </CCardFooter>
      </CCollapse>
    </CCard>
  </div>
</template>

<script>
import {GOOD_FUNCTIONS, GOOD_MOUNT_FUNCTION, GOOD_TABLE_OPTIONS} from '../js/goodTables'
import SaveButtons from "@/component/parts/SaveButtons";

export default {
  name: 'AgencyBranchManage',
  components:{
    SaveButtons
  },
  data: () => {
    return Object.assign({}, GOOD_TABLE_OPTIONS(), {
      columns: [
        {label: 'No', field: 'no', width: '80px', sortable: false},
        {label: 'Id', field: 'id', width: '80px'},
        {label: '事業所名称', field: 'agencyBranchName',},
        {label: '住所', field: 'address',},
        {label: '登録日時', field: 'created', width: '190px'},
        {label: '更新日時', field: 'updated', width: '190px'},
        {label: '', field: 'after', sortable: false},
      ],
      selected: "",
      isCollapsed: true,
      orgTypes: [],
      prefectures: [],
      cities: [],
      agencyName:null,
      formData: {
        agencyId:null,
        id: null,
        branchName: null,
        prefectureId: null,
        city: null,
        contactName: null,
        contactDept: null,
        contactTel: null,
        contactMemo: null
      },
    })
  },
  methods: Object.assign({}, GOOD_FUNCTIONS(), {
    checkedNamePosition: function (data) {
      console.log("checkedNamePosition:function(data){", data);
    },

    formInit: function () {
        this.formData = {agencyId: this.$route.params.id};
    }, save: function () {
      this.axios.post('/api/agencyBranch/agencyBranchSave', this.formData, {headers: {'Content-Type': 'application/json'}}).then(response => {
      this.goodPaging();
        this.formData.id = response.data.result;
      }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);
    }, update: function (data) {
      this.axios.get(`/api/agencyBranch/${data.id}`).then(response => {
        this.formData = Object.assign({}, response.data.result);
        this.isCollapsed = false;
      }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);
    },
    goodPaging: function (isPageChange) {
      if (!isPageChange) this.pagingParams.currPage = 1;//ページが以外が変わったら最初から検索する
      this.isLoading = true;

      this.axios.post(`/api/agencyBranch/searchPaging/${this.$route.params.id}`, this.pagingParams, {headers: {'Content-Type': 'application/json'}}).then(response => {
        this.totalRecords = response.data.result.totalElements;
        this.rows = response.data.result.content;
      }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);
    },getAgencyData: function () {

      this.axios.get(`/api/agencyInfo/${this.$route.params.id}`).then(response => {
        console.log("/api/agency//api/agency//api/agency/",response);
        this.agencyName = response.data.result.agencyName;
      }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);

    }, getOptions: function () {
      this.axios.post('/api/agency/getOptions', {}, {headers: {'Content-Type': 'application/json'}}).then(response => {
        this.orgTypes = response.data.result.orgTypes;
        this.prefectures = response.data.result.prefectures;
      }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);
    },
  }),
  mounted() {
    this.getOptions();
    this.goodPaging();
    this.getAgencyData();
    GOOD_MOUNT_FUNCTION(this);
      this.formData.agencyId = this.$route.params.id;
  },
  // watch: {
  //   'formData.prefectureId': function (newValue, oldValue) {
  //     if (newValue == oldValue) {
  //       return;
  //     }
  //     console.log("newValue, oldValue", newValue, oldValue);
  //     if (newValue == null) {
  //       this.cities = [];
  //       this.formData.cityId = null;
  //       return;
  //     }
  //     var ken = this.prefectures.find((kenItem) => kenItem.prefectureId == newValue);
  //     this.cities = Object.assign([], ken.cities);
  //   }
  // },
}
</script>
<style scoped>
label {
  color: #3C4B64;
}
#depats{
  list-style: none;
}


.pointer:hover {
  cursor: pointer;
}
</style>